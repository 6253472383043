import app from "./firebase";
import {
  getStorage,
  ref,
  uploadBytes
} from "firebase/storage";
import uniqid from "uniqid";
import JSZip from "jszip";
import Compressor from "compressorjs";
import * as Sentry from "@sentry/react";

const storage = getStorage(app);

const uploadMedia = async (images) => {
  try {
    const zip = new JSZip();
    const folder = uniqid();
    let thumbnailImage;
    const listingRef = ref(storage, `Listing/${folder}/${folder}.zip`);
    const compression = (count) => {
      return new Promise((resolve, reject) => {
        new Compressor(images[count], {
          quality: 0.9,
          mimeType: "image/webp",
          maxHeight: 1080,
          maxWidth: 1920,
          success(file) {
            if (count == 0) {
              thumbnailImage = `${uniqid()}.webp`;
              zip.file(thumbnailImage, file, { base64: true });
              resolve();
              return;
            }
            zip.file(`${uniqid()}.webp`, file, { base64: true });
            resolve();
          },
          error(e) {
            reject(e);
          },
        });
      });
    };
    for (let i = 0; i < images.length; i++) {
      await compression(i).catch((e) => {
        throw e;
      });
    }
    const content = await zip.generateAsync({ type: "blob" });
    await uploadBytes(listingRef, content);
    return { folder, thumbnailImage };
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export { uploadMedia };
