import { algoliasearch } from "algoliasearch";

const client = algoliasearch(
  import.meta.env.VITE_ALGOAPPID,
  import.meta.env.VITE_ALGOADMINAPI
);
const baseIndex = import.meta.env.VITE_ALGOINDEX;
const ascIndex = import.meta.env.VITE_ASCALGOINDEX;
const dscIndex = import.meta.env.VITE_DSCALGOINDEX;
export default { client, baseIndex, ascIndex, dscIndex };
