import app from "./firebase";
import {
  getFirestore,
  getDocs,
  addDoc,
  collection,
  getDoc,
  doc,
  Timestamp,
  query,
  orderBy,
  startAfter,
  where,
  arrayUnion,
  arrayRemove,
  writeBatch,
  onSnapshot,
  updateDoc,
  limit,
  setDoc,
  increment
} from "firebase/firestore";
import { uploadMedia } from "./firestorage";
import { functions, httpsCallable } from "./functions";
import { getCurrentUser } from "./fireauth";
import * as Sentry from "@sentry/react";

const db = getFirestore(app);

async function CreateNewUser(dataProps) {
  try {
    await setDoc(doc(db, "Users", dataProps.uid), {
      contactNumbers: [],
      preferredContact: "call",
      watchList: [],
      ...dataProps,
    });
    const SendWelcome = httpsCallable(functions, "SendWelcome");
    await SendWelcome({
      email: dataProps.email,
      contactName: dataProps.contactName,
    }).catch((e) => {
      Sentry.setContext("Additional Info", {
        dataProps,
      });
      Sentry.captureException(e);
      return;
    });
    return;
  } catch (e) {
    Sentry.setContext("Additional Info", {
      dataProps,
    });
    Sentry.captureException(e);
    throw e;
  }
}
async function GetDocuments(count, queryParam = null, next = false, last) {
  try {
    const limitCount = count + 1;
    let lastDocument;
    let documents = [];
    let isMore = false;
    let q;
    if (queryParam) {
      if (next) {
        q = query(
          collection(db, "UserUploads"),
          where(queryParam.key, queryParam.operator, queryParam.value),
          orderBy("refreshDate", "desc"),
          startAfter(last),
          limit(limitCount)
        );
      } else {
        q = query(
          collection(db, "UserUploads"),
          where(queryParam.key, queryParam.operator, queryParam.value),
          orderBy("refreshDate", "desc"),
          limit(limitCount)
        );
      }
    } else {
      if (next) {
        q = query(
          collection(db, "UserUploads"),
          orderBy("refreshDate", "desc"),
          startAfter(last),
          limit(limitCount)
        );
      } else {
        q = query(
          collection(db, "UserUploads"),
          orderBy("refreshDate", "desc"),
          limit(limitCount)
        );
      }
    }
    await getDocs(q).then((snap) => {
      snap.forEach((doc) => {
        documents.push(doc.data());
      });
      if (snap.docs.length == limitCount) {
        isMore = true;
        documents.pop();
        lastDocument = snap.docs[snap.docs.length - 2];
      } else {
        lastDocument = snap.docs[snap.docs.length - 1];
      }
    });
    return { documents, isMore, lastDocument };
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}

async function AddNewDocument(data, files, premiumBool, userID, callback) {
  try {
    const shardNumber = 10;
    const docRef = doc(db, "Users", userID);
    const userSnap = await getDoc(docRef);
    callback(10);
    if (!userSnap.exists()) {
      return { msg: "Login Required", success: false };
    }
    const { folder, thumbnailImage } = await uploadMedia(files);
    callback(40);
    await addDoc(collection(db, "UserUploads"), {
      userId: userID,
      uploadDate: Timestamp.now(),
      refreshDate: Timestamp.now(),
      soldDate: Timestamp.now(),
      storageFolder: folder,
      images: [],
      thumbnail: [],
      soldStatus: false,
      premium: premiumBool,
      processing: true,
      watching: [],
      prevPrice: 0,
      totalWatched: 0,
      shared: 0,
      contactNumbers: userSnap.data().contactNumbers,
      contactName: userSnap.data().contactName,
      preferredContact: userSnap.data().preferredContact,
      contactEmail: userSnap.data().email,
      ...data,
    }).then(async (uploadDoc) => {
      let batch = writeBatch(db);
      batch.update(uploadDoc, { objectID: uploadDoc.id });
      batch.update(uploadDoc, { num_shards: shardNumber });
      for (let i = 0; i < shardNumber; i++) {
        const shardRef = doc(
          db,
          `UserUploads/${uploadDoc.id}/viewShards/${i.toString()}`
        );
        batch.set(shardRef, { count: 0 });
      }
      batch.commit().then(() => {
        callback(20);
      });
    });
    const EditImages = httpsCallable(functions, "EditImages");
    await EditImages({ folder, thumbnailImage });
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setLevel("fatal");
      Sentry.setContext("Upload Data", {
        userID,
        isPremium: premiumBool,
      });
      Sentry.captureException(e);
    });
    throw e;
  }
}
const UpdateWatchList = async (id, type) => {
  try {
    const user = getCurrentUser();
    if (user == null) {
      throw new Error("Current User Not found");
    }
    if (type) {
      updateDoc(doc(db, `UserUploads/${id}`), {
        watching: arrayUnion(user.uid),
        totalWatched: increment(1),
      });
    } else {
      updateDoc(doc(db, `UserUploads/${id}`), {
        watching: arrayRemove(user.uid),
        totalWatched: increment(-1),
      });
    }
    return { msg: "Updated WatchList", success: true };
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
function onDocumentChange(id, func) {
  try {
    const q = query(
      collection(db, "UserUploads"),
      where("watching", "array-contains", id)
    );
    const unsubscribe = onSnapshot(q, func);
    return unsubscribe;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}
async function UpdateDocumentField(id, collection, value, time, key) {
  try {
    if (time) {
      await updateDoc(doc(db, collection, id), {
        ...value,
        key: Timestamp.now(),
      });
      return { msg: "Updated", success: true };
    }
    await updateDoc(doc(db, collection, id), value);
    return { msg: "Updated", success: true };
  } catch (e) {
    Sentry.setContext("Updating Document", {
      docID: id,
      collection,
      value,
    });
    Sentry.captureException(e);
    throw e;
  }
}
async function SetDocumentField(id, collection, value) {
  try {
    await setDoc(doc(db, collection, id), value, { merge: true });
    return { msg: "Updated", success: true };
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}
async function incrementPageView(id, num_shards) {
  try {
    const shard_id = Math.floor(Math.random() * num_shards).toString();
    const shard_ref = doc(db, `UserUploads/${id}/viewShards/${shard_id}`);
    await updateDoc(shard_ref, { count: increment(1) });
  } catch (e) {
    Sentry.captureException(e);
  }
}
function getViewCount(id) {
  return getDocs(collection(db, `UserUploads/${id}/viewShards`)).then(
    (querySnap) => {
      let totalCount = 0;
      querySnap.forEach((doc) => {
        totalCount += doc.data().count;
      });
      return totalCount;
    }
  );
}
function GetRandomDocuments(
  collectionRef,
  count,
  originalID,
  isPremium = false,
  type
) {
  try {
    const queries = [];
    const documentNumber = [];
    for (let i = 0; count > i; i++) {
      documentNumber.push(Math.floor(Math.random() * (2500000 + 1) + 1));
    }
    documentNumber.map((item) => {
      const key = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      let tempQuery;
      if (isPremium) {
        tempQuery = query(
          collection(db, collectionRef),
          where("premium", "==", true),
          where(`random.${key}`, ">=", item),
          limit(1)
        );
      } else {
        tempQuery = query(
          collection(db, collectionRef),
          where("type", "==", type),
          where(`random.${key}`, ">=", item),
          limit(1)
        );
      }
      queries.push(getDocs(tempQuery));
    });
    return Promise.all(queries).then((snapArray) => {
      const results = [];
      for (let i = 0; snapArray.length > i; i++) {
        snapArray[i].forEach((doc) => {
          if (doc.exists()) {
            if (doc.data() == undefined) {
              return;
            }
            let data = doc.data();
            data.refID = doc.id;
            results.push(data);
          }
        });
      }
      const unique = [
        ...new Map(results.map((item) => [item["refID"], item])).values(),
      ];
      return unique;
    });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}
async function GetSingleDocument(
  collectionRef,
  docID = null,
  queryParam = null
) {
  try {
    let data;
    if (queryParam) {
      const q = query(
        collection(db, collectionRef),
        where(queryParam.key, queryParam.operator, queryParam.value),
        limit(1)
      );
      const docSnap = await getDocs(q);
      docSnap.forEach((doc) => {
        if (doc.exists()) {
          if (doc.data() == undefined) {
            data = null;
            return;
          }
          data = doc.data();
          data.refID = doc.id;
          return;
        }
        data = null;
      });
    } else {
      const docRef = doc(db, collectionRef, docID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        data = docSnap.data();
        data.refID = docSnap.id;
      } else {
        data = null;
      }
    }
    return data;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}
function GetMultipleUniqueDocs(collection, listArray, byParam = false) {
  try {
    const dataList = [];
    if (byParam) {
      listArray.map((params) => {
        const data = GetSingleDocument(collection, null, params);
        if (data) {
          dataList.push(data);
        }
      });
    } else {
      listArray.map((docID) => {
        const data = GetSingleDocument(collection, docID);
        if (data) {
          dataList.push(data);
        }
      });
    }
    return Promise.all(dataList);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}
async function CalculateVoucher(voucherArray, uid, operation, cb) {
  try {
    let promiseArray = [];
    promiseArray.push(GetSingleDocument("AdPricing", "allPricing"));
    voucherArray.map((item) => {
      promiseArray.push(
        GetSingleDocument(`Vouchers/${item.refID}/Claims`, null, {
          key: "uid",
          operator: "==",
          value: uid,
        })
      );
    });
    let Price;
    let data = await Promise.all(promiseArray);
    switch (operation) {
      case 1:
        Price = data[0].premiumAd.priceUSD;
        break;
      case 2:
        Price = data[0].basicAd.priceUSD;
        break;
      case 3:
        Price = data[0].premiumUpgrade.priceUSD;
        break;
      case 4:
        Price = data[0].refreshAd.priceUSD;
        break;
      default:
        throw Error("Invalid Ad Price Operation");
    }
    let discountedPrice;
    voucherArray.map((item) => {
      discountedPrice = Price - Price * (item.discount / 100);
    });
    if (discountedPrice == 0) {
      await IncrementVoucher(uid, voucherArray);
      cb("Voucher", discountedPrice);
    } else {
      throw Error("Free Voucher Not Equal To Zero");
    }
  } catch (e) {
    Sentry.setContext("Voucher Calculation", {
      userID: uid,
      vouchers: voucherArray,
    });
    Sentry.captureException(e);
    throw e;
  }
}
function IncrementVoucher(uid, voucherList) {
  try {
    const listPromise = [];
    voucherList.map((value) => {
      listPromise.push(
        setDoc(
          doc(db, "Vouchers", `${value.refID}/Claims/${uid}`),
          {
            claimed: increment(1),
          },
          { merge: true }
        )
      );
    });
    return Promise.all(listPromise);
  } catch (e) {
    Sentry.setContext("Voucher Increment", {
      userID: uid,
      vouchers: voucherList,
    });
    Sentry.captureException(e);
  }
}
export {
  IncrementVoucher,
  CreateNewUser,
  GetDocuments,
  AddNewDocument,
  UpdateWatchList,
  onDocumentChange,
  UpdateDocumentField,
  GetSingleDocument,
  GetMultipleUniqueDocs,
  incrementPageView,
  getViewCount,
  GetRandomDocuments,
  SetDocumentField,
  CalculateVoucher,
};
